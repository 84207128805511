import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

class SupportPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          title="Support | Kingdom Kids"
          description="Please consider supporting our ministry through prayer, donations or by volunteering. Join with God on what he is accomplishing here in Logan."
        />
        <Nav />
        <div className="kdk-nav-bg kdk-top-section"></div>
        <section className="kdk-support">
          <div className="container">
            <h1>Thank you for your support</h1>
            <p className="text-lead">Here are some ways you can support us:</p>
            <section className="kdk-support-item">
              <h2>Pray</h2>
              <p>
                Please pray that God would save many kids from the kingdom of
                darkness so that they can live with Jesus in the kingdom of
                light.
              </p>
              <p>
                If you would like to receive email updates for prayer requests,
                please subscribe below:
              </p>
              <a href="mailto:info@kingdomkids.org.au" className="btn-default">
                Get Updates
              </a>
            </section>
            <section className="kdk-support-item">
              <h2>Donate</h2>
              <p>
                Would you consider supporting us financially to reach more kids
                with the love of God? Our team members volunteer their time and
                effort for this cause so that 100% of donations can go directly
                to this ministry.
              </p>
              <Link to="/donate" className="btn-default">
                Ways to give
              </Link>
            </section>
            <section className="kdk-support-item">
              <h2>Join</h2>
              <p>
                Do you have a heart for kids, and are passionate about seeing
                Logan turned for Jesus? Please reach out to us and enquire about
                becoming a volunteer or leader.
              </p>
              <a href="mailto:info@kingdomkids.org.au" className="btn-default">
                Contact Us
              </a>
            </section>
          </div>
        </section>
      </Layout>
    )
  }
}

export default SupportPage

export const pageQuery = graphql`
  query SupportPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
